import React, { Props } from 'react';
import Button from '@material-ui/core/Button';

import PropTypes, { bool } from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classes from './Home.module.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import mwprofile from '../../assets/mw-profile.jpg';

import Contact from '../contact/Contact';
import Cv from '../cv/Cv';

interface HomeState { menuOpen: boolean; anchorEl: any; showContact: boolean };

class Home extends React.Component<{}, HomeState> {

    state = {
        menuOpen: false,
        showContact: false,
        showCv: false,
        anchorEl: null
    }

    handleMenu = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
        this.setState(prevState => ({
            menuOpen: !prevState.menuOpen
        }))
    };

    handleMenuClose = () => {
        this.setState(prevState => ({
            menuOpen: !prevState.menuOpen,
            anchorEl: null
        }))
    };
    showContact = () => {
        this.setState(prevState => ({
            showContact: true,
            showCv: false,
            menuOpen: !prevState.menuOpen,
            anchorEl: null
        }))
    }

    showCv = () => {
        this.setState(prevState => ({
            showContact: false,
            showCv: true,
            menuOpen: !prevState.menuOpen,
            anchorEl: null
        }))
    }

    render() {
        let contactHtml = this.state.showContact ? <Contact /> : null;
        let cvHtml = this.state.showCv ? <Cv /> : null;
        return (
            <div>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton className={classes.menuButton} color="inherit" onClick={this.handleMenu}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            Mac Windle
                        </Typography>
                        <Avatar alt="Mac Windle" src={mwprofile} />
                        <Menu
                            id="menu-appbar"
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={this.state.menuOpen}
                            onClose={this.handleMenuClose}
                        >
                            <MenuItem onClick={this.showCv}>CV</MenuItem>
                            <MenuItem onClick={this.showContact}>Contact</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                {contactHtml}
                {cvHtml}
                <div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
export default Home;