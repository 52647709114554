import React from 'react'

const cv: React.FC = () => {
    return (
        <div>
            <h2>This is my CV</h2>
        </div>
    );
}

export default cv;