import React from 'react'
import classes from './Contact.module.css';
import FlexView from 'react-flexview';

const contact: React.FunctionComponent = () => {
    return (
        <div>
            <div className={classes.contactContainer}>
                <FlexView hAlignContent="left" column={true} marginLeft={40}>
                    <h1>Contact me</h1>
                    <ul style={{ listStyle: 'none', alignItems: 'left' }}>
                        <li>11123456789</li>
                        <li>test@test.com</li>
                    </ul>
                </FlexView>
            </div>
        </div>
    );
}

export default contact;